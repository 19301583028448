import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['searchContainer', 'query', 'hiddenIdField', 'resultsContainer', 'resultsFrame', 'selectedContainer', 'selectedValue'];
  static values = { url: String, minLength: Number, debounceTimer: Number }

  connect() {
    if (this.hiddenIdFieldTarget.value && this.hiddenIdFieldTarget.dataset.selectedValue) {
      var selectedValue = this.hiddenIdFieldTarget.dataset.selectedValue;
      this.renderValue(selectedValue, this.hiddenIdFieldTarget.value);
    }
  }

  inputHandler(event) {
    this.debounce(this.debouncedInputHandler.bind(this, event), 500)
  }

  debouncedInputHandler(event) {
    if (event.target.value.length > this.minLengthValue) {
      this.search()
    } else {
      this.hideElement(this.resultsContainerTarget)
    }
  }

  blurHandler(event) {
    if (!this.resultsContainerTarget.contains(event.relatedTarget) && !this.searchContainerTarget.contains(event.relatedTarget)) {
      this.hideElement(this.resultsContainerTarget)
    }
  }

  search() {
    this.resultsFrameTarget.src = this.urlValue.replace(/%s|%25s/g, encodeURIComponent(this.queryTarget.value))
    this.showElement(this.resultsContainerTarget)
  }

  select(event) {
    var selectedId = event.params.id
    var selectedValue = event.params.value

    this.renderValue(selectedValue, selectedId);
    this.hiddenIdFieldTarget.dispatchEvent(new Event('input'))
    this.dispatch("select", { detail: { id: selectedId, value: selectedValue } })
    this.hiddenIdFieldTarget.dispatchEvent(new Event('change'))
    event.preventDefault()
  }

  renderValue(selectedValue, selectedId) {
    this.hideElement(this.resultsContainerTarget);
    this.hideElement(this.searchContainerTarget);
    this.showElement(this.selectedContainerTarget);
    this.selectedValueTarget.innerText = selectedValue;
    this.hiddenIdFieldTarget.value = selectedId;
  }

  clear(event) {
    this.selectedValueTarget.innerText = ""
    this.hiddenIdFieldTarget.value = ""
    this.hiddenIdFieldTarget.dispatchEvent(new Event('input'))
    this.queryTarget.value = ""
    this.showElement(this.searchContainerTarget)
    this.hideElement(this.selectedContainerTarget)
    this.queryTarget.focus()

    this.dispatch("clear")
    event.preventDefault()
  }

  hideElement(elem) {
    elem.classList.remove("visible")
    elem.classList.add("hidden")
  }

  showElement(elem) {
    elem.classList.remove("hidden")
    elem.classList.add("visible")
  }

  debounce(callback, time) {
    clearTimeout(this.debounceTimerValue);
    this.debounceTimerValue = setTimeout(callback, time);
  }
}