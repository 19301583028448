import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'video',
    'shortCode',
  ];

  connect() {
    if (this.videoTarget.checkVisibility()) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then(stream => {
        this.startScanning();
      }).catch(err => {
        document.getElementById('request-camera-access').classList.remove('hidden');
      });
    }
  }

  disconnect() {
    this.codeReader.reset();
  }

  requestCameraAccess() {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
    .then(stream => {
      this.startScanning();
    }).catch(err => {
      console.log(err)
    });
  }

  startScanning() {
    document.getElementById('request-camera-access').classList.add('hidden');
    import("@zxing/library/esm").then(reader => {
      this.codeReader = new reader.BrowserQRCodeReader();
      this.notFound = reader.NotFoundException;
      this.checksum =  reader.ChecksumException;
      this.format =  reader.FormatException;
      this.codeReader.timeBetweenDecodingAttempts = 500
      this.decodeContinuously(this.codeReader, undefined, this.videoTarget);
    })
  }

  decodeContinuously(codeReader, selectedDeviceId, video) {
    codeReader.decodeFromInputVideoDeviceContinuously(selectedDeviceId, video, (result, err) => {
      if (result) {
        const arr = result.text.split("/")
        const shortCode = arr[arr.length - 1]
        const shortCodeReg = new RegExp("^[A-Za-z]{5}$")
        if (shortCodeReg.test(shortCode)) {
          this.saveCandidate(shortCode)
        } else {
          this.showErrorMessage("Invalid QR code. Please try again.")
        }
      }
  
      if (err) {  
        if (err instanceof this.notFound) {
          console.log('No QR code found.')
        } else {
          this.showErrorMessage("Error decoding QR code. Please try again.")
        }
      }
    })
  }
  
  saveCandidate(shortCode) {
    this.shortCodeTarget.value = shortCode
    this.shortCodeTarget.closest('form').requestSubmit()
  }

  showErrorMessage(message) {
    window.dispatchEvent(new CustomEvent('flash-component-modal-alert-show'))
    document.getElementById('flash-component-modal-alert').getElementsByTagName('p')[0].innerText = message
  }
}